
import getMainNavigationQuery from '~/graphql/queries/MainNavigation.graphql';
import { print } from 'graphql/language/printer';
import gql from 'graphql-tag';

export default {
    data() {
        return {
            menuVisible: false,
            globalSet: null
        };
    },

    async fetch() {
        const { data } = await this.$gql.executeQuery(
            getMainNavigationQuery
        );

        if (data && data.globalSet) {
            this.globalSet = data.globalSet;
        }
    },

    mounted: async() => {
        /* eslint-disable */
        const query = gql`
            query sitemapElements($siteId: String = "1") {
                sitemap(siteId: $siteId) {
                    sectionId
                    lastMod
                    priority
                    changeFreq
                    elements {
                        url
                        lastMod
                    }
                }
            }
        `;

        await fetch('https://cer-cms.acc.ganymede.digitalnatives.nl/graphql', {
            method: 'POST',
            headers: {
                Authorization: `Bearer 0oFmKWuU9DO5yCrgItcfQayiOAZ430vn`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: print(query),
                variables: {
                    siteId: '2'
                }
            })
        }).then((response) => {
            console.log(response);
            return response.json();
        });
    }
};
